import React from 'react'
import { Button, Image } from 'react-bootstrap'
import '../assets/css/components/buttonModel.css'
import icon from '../assets/images/icons/vector_btn.svg'

function ButtonModel(props) {
    const { text, onClick } = props;
    return (

        <>
            <div className='d-flex button-body'>
                <Button className='button-model' onClick={onClick}><span className='text-spn'>{text}</span></Button>
                <div className='right'>
                    <Image className="btn-icon" src={icon} alt='icon' />
                </div>
            </div>
        </>
    )
}

export default ButtonModel
