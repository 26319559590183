import React from 'react'
import '../assets/css/components/parallaxFirst.css'
import { ButtonModel } from '../components/index'
import { Image } from 'react-bootstrap'
import bg1 from '../assets/images/background/parallax_bg.png'
import bg2 from '../assets/images/background/parallax_bg2.png'
import textImg from '../assets/images/title1.svg'
import textImg_en from '../assets/images/title1_en.svg'
import intro from '../assets/images/intro.png'
import { ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax'
import { Nav } from 'react-bootstrap';
import { Link } from 'react-scroll'
import { useTranslation } from 'react-i18next';



function ParallaxFirst() {
    const { t, i18n } = useTranslation();
    
    const open = () => {
        window.open("https://www.youtube.com/watch?v=qKy_WvUeae0", "_blank");
    }
    return (
        <>
            <div className='parallax-body' id='first'>
                <ParallaxProvider>
                    <ParallaxBanner
                        className="parallax"
                        layers={[
                            {
                                shouldAlwaysCompleteAnimation: true,
                                expanded: false,
                                translateY: [0, 0],
                                image: bg1,
                                // speed: 0.1,
                                // amount: 0.3,
                                className: 'bg-img'
                            },
                            {
                                expanded: false,
                                shouldAlwaysCompleteAnimation: true,
                                scale: [1, 0.1, ' easeOutCirc'],
                                translateY: 10,
                                // amount: 1,
                                // speed: 0.8,
                                children:
                                (i18n.language  == 'tr') ? (
                                    <Image className='text-img' src={textImg} alt='text-img' />
                                ):(
                                    <Image className='text-img' src={textImg_en} alt='text-img'/>
                                )
                                   
                            },
                            {
                                expanded: false,
                                shouldAlwaysCompleteAnimation: true,
                                scale: [1, 0.1, ' easeOutCirc'],
                                style: { zIndex: "999" },
                                translateY: 10,
                                // amount: 1,
                                // speed: 0.8,
                                children:
                                    <Image onClick={()=>open()} className='intro' style={{ width:200 ,objectFit:"contain" , cursor:"pointer"}} src={intro} alt='text-img' />
                            },
                            {
                                scale: [1, 1.1, 'easeOutCubic'],
                                shouldAlwaysCompleteAnimation: true,
                                translateY: [30, -2],
                                image: bg2,
                                // speed: 2,
                                // amount: 2,
                                className: 'bg-img'

                            },
                            {
                                style: { zIndex: "999" },
                                translateY: [60, 30],
                                // amount: 0.4,
                                // speed: 0.6,
                                children:
                                    <div className='d-flex justify-content-center'>
                                        <Nav className="flex-column nav-body">
                                            <Link to="scholarship"
                                                activeClass='active-link'
                                                spy={true}
                                                smooth={true}
                                                offset={-70}
                                                duration={50}
                                            >
                                                <ButtonModel  text={t('menuContact')} />
                                            </Link>
                                        </Nav>
                                    </div>
                            },
                        ]}
                    />
                </ParallaxProvider>
            </div>

        </>
    )
}

export default ParallaxFirst
