// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/ChakraPetch-Medium.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Chakra Petch";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

body {
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box !important;
  background-color: #0A012D!important;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/style.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,4CAA2C;AAC7C;;AAEA;EACE,oBAAoB;EACpB,qBAAqB;EACrB,iCAAiC;EACjC,mCAAmC;AACrC","sourcesContent":["@font-face {\n  font-family: \"Chakra Petch\";\n  src: url(\"../fonts/ChakraPetch-Medium.ttf\");\n}\n\nbody {\n  margin: 0 !important;\n  padding: 0 !important;\n  box-sizing: border-box !important;\n  background-color: #0A012D!important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
