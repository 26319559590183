import React from 'react'
import '../../assets/css/sidebar.css'
import { Nav } from 'react-bootstrap';
import { Link } from 'react-scroll'
import { useTranslation } from 'react-i18next';


function Sidebar() {
    const { t } = useTranslation();
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    return (
        <>
            <div className='sidebar-body'>
                <Nav className="flex-column nav-body">
                <Link onClick={goToTop}
                        activeClass='active-link'
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={50}
                    >
                        {t('menuHome')}
                    </Link>
                    <Link to="about"
                        activeClass='active-link'
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={50}
                    >
                        {t('menuAbout')}
                    </Link>
                    <Link to="brands"
                        activeClass='active-link'
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={50}
                    >
                        {t('menuBrands')}
                    </Link>
                    <Link to="what"
                        activeClass='active-link'
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={50}
                    >
                        {t('menuWhatWeDo')}
                    </Link>
                    {/* <Link to="scholarship"
                        activeClass='active-link'
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={50}
                    >
                        BURS BAŞVURUSU
                    </Link> */}
                    <Link to="scholarship"
                        activeClass='active-link'
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={50}
                    >
                            {t('menuContact')}
                    </Link>
                </Nav>
            </div>
        </>
    )
}

export default Sidebar
