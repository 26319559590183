import React from 'react'
import { Row, Col, Image } from 'react-bootstrap';
import '../assets/css/components/brands.css'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from 'swiper';
import kodtic from '../assets/images/kodtic.svg'
import kodapi from '../assets/images/kodapi.png'
import kodpex from '../assets/images/kodpex.svg'

import ButtonModel from './ButtonModel';
import { useTranslation } from 'react-i18next';

function Brands() {
    const { t } = useTranslation();
    const openKodpex = () => {
        window.open("https://www.kodpex.com/", "_blank");
    }
    const openKodapi = () => {
        window.open("https://www.kodapi.com/", "_blank");
    }
    const openKodtic = () => {
        window.open("https://www.kodtic.com/", "_blank");
    }
    return (
        <>
            <div className='brands-body' id='brands'>
                <div className='container'>
                    <Row className='brands-row w-100'>
                        <Col md={8} sm={12}>
                            <h1 className='title'>{t('brandsTitle')}</h1>
                            <Row className='content w-100'>
                                <Col md={6}>
                                    <p className='first-content'> 
                                    <p dangerouslySetInnerHTML={{ __html:t('brandsDesc1') }} />
                                    </p>
                                </Col>
                                <Col md={6}>
                                    <p className='second-content'>
                                    <p dangerouslySetInnerHTML={{ __html:t('brandsDesc2') }} />
                                    
                                  
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4} sm={12}>
                            <Swiper
                                spaceBetween={50}
                                loop={true}
                                speed={2000}
                                autoplay={{
                                    delay: 6000,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay]}
                            >
                                <SwiperSlide >
                                    <div className='slider'>
                                        <div className='slider-area'>
                                            <Image className='mx-auto d-block' src={kodtic} alt='kodtic' />
                                            <p className='description'>{t('kodtic')}</p>
                                            <div className='d-flex justify-content-center'>
                                                <ButtonModel onClick={()=>openKodtic()} text={t('discover')} />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide >
                                    <div className='slider'>
                                        <div className='slider-area'>
                                            <Image className='mx-auto d-block' src={kodapi} alt='kodapi' />
                                            <p className='description'>{t('kodapi')}</p>
                                            <div className='d-flex justify-content-center'>
                                                <ButtonModel onClick={()=>openKodapi()} text={t('discover')} />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide >
                                    <div className='slider'>
                                        <div className='slider-area'>
                                            <Image className='mx-auto d-block' src={kodpex} alt='kodpex' />
                                            <p className='description'>{t('kodpex')}</p>
                                            <div className='d-flex justify-content-center'>
                                                <ButtonModel onClick={()=>openKodpex()} text={t('discover')} />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>


                            </Swiper>
                        </Col>
                    </Row >
                </div>
            </div>
        </>
    )
}

export default Brands;
