import React from 'react'
import '../assets/css/components/scholarship.css'

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import h1 from "../assets/images/scholarshiph1.svg"
import btn from "../assets/images/basvuru.svg"
import logo from "../assets/images/logo.svg"

function Scholarship() {
 
    return (
        <>
        {/* <div className='scholarship-body'>
            <div className='container-fluid d-flex align-items-center justify-content-center'>
                <div className='row w-100 d-flex align-items-center justify-content-center'>
                    <div className='col-12 d-flex align-items-center justify-content-center'>
                        <img style={{maxWidth:"100%"}} src={h1}></img>
                    </div>
                    <div className='mt-5 col-lg-7 col-12 d-flex align-items-center justify-content-center'>
                        <p>
                        Şİrketİmİz tarafından her yıl +2 kİşİye burs sağlanmaktadır. Başvurular Ekİm ayına kadar toplanmakta ve onlIne bir görüşmeden başvuru sonucu maIl olarak İletİlmektedİr. 

                        Ayrıca burs kazanan öğrencİlere şirketİmizde staj yapma ve staj sonrası İş İmkanı da sunulmaktadır. 

                        Burs başvurusu yapabilmenİn şartları, bİlgisayar veya yazılım mühendİslİğİ okuyor/okuyacak olmak ve mezunİyet sonrası Bursa'da yaşamayı kabul edİyor olmaktır.

                        </p>
                    </div>
                    <div className='col-lg-7 col-12 d-flex align-items-center justify-content-center'>
                        <img className='mt-5' style={{maxWidth:"100%"}} src={btn}></img>
                    </div>
                    <div style={{marginTop:"500px"}} className='col-12 d-flex justify-content-center align-items-center'>
                        <div className='container d-flex justify-content-center align-items-center'>
                            <div className='row w-100'>
                                <div className='col-12 col-md-4'>
                                    <div className='row w-100'>
                                        <div className='col-12'>
                                            <img src={logo}></img>
                                        </div>
                                        <div className='col-12'>
                                            <p className='mt-3' style={{lineHeight:"21px",textAlign:"left"}}>
                                            Kodpİlot, İşİnİ seven yazılımcılardan oluşan, e-tİcaret, web sİtesİ, mobİl programlama, özel yazılımlar ve dİjİtal reklam hİzmetlerİ veren bİr teknolojİ ve yazılım şİrketİdİr.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-3'>
                                    <div className='row w-100'>
                                        <div className='col-12'>
                                            <p style={{color:"#FC0DC3",textAlign:"left"}}>MENÜ</p>
                                        </div>
                                        <div className='col-12'>
                                            <p className='mt-3' style={{lineHeight:"21px",textAlign:"left"}}>
                                            ANASAYFA <br></br>
                                            HAKKIMIZDA <br></br>
                                            MARKALARIMIZ <br></br>
                                            NELER YAPTIK? <br></br>
                                            BURS BAŞVURUSU <br></br>
                                            İLETİŞİM         
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-2'>

                                </div>
                                <div className='col-12 col-md-3'>
                                    <div className='row w-100'>
                                        <div className='col-12'>
                                            <p className='footerp'>İLETİŞİM</p>
                                        </div>
                                        <div className='col-12'>
                                            <p className='footerp'>EMAIL:INFO@KODPILOT.COM</p>
                                            <p className='footerp'>TELEFON:0850 307 3637</p>
                                            <p className='footerp'>ADRES:ODUNLUK MAH. ODUNLUK CAD. NO:13/C, NİLÜFER/BURSA</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        </>
    )
}

export default Scholarship
