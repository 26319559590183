import React from 'react'
import logo from "../../assets/images/logo.svg"
import "../../assets/css/footer.css"
import { Nav } from 'react-bootstrap';
import { Link } from 'react-scroll'
import '../../assets/css/components/scholarship.css'

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import h1 from "../../assets/images/title_burs.svg"
import h1_en from "../../assets/images/title_burs_en.svg"
import btn from "../../assets/images/basvuru.svg"
import btn_en from "../../assets/images/btn_contact_en.svg"

import rock from "../../assets/images/footer-rock.png"
import { useTranslation } from 'react-i18next';

function Footer() {
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    const { t, i18n } = useTranslation();
    return (
        <div className='scholarship-body' id='scholarship'>
            <div className='container-fluid d-flex align-items-center justify-content-center position-relative'>
                <div className='upper-footer row w-100 d-flex align-items-center justify-content-between flex-column '>
                    <div className='col-12 d-flex align-items-center justify-content-center'>
                       
                        {i18n.language  == 'tr' ? ( <img style={{maxWidth:"100%"}} src={h1}></img>):( <img style={{maxWidth:"100%"}} src={h1_en}></img>)}
                    </div>
                    <div className='mt-5 col-lg-7 col-12 d-flex align-items-center justify-content-center'>
                       <p dangerouslySetInnerHTML={{ __html: t('scholarship')}} />
                    </div>
                    <div className='col-lg-7 col-12 d-flex align-items-center justify-content-center'>
                      
                        {i18n.language  == 'tr' ? ( <img style={{maxWidth:"100%"}} src={btn}></img>):( <img style={{maxWidth:"100%"}} src={btn_en}></img>)}
                    </div>
                    <div className='footer-section col-12 d-flex justify-content-center align-items-center position-absolute' style={{zIndex:"2"}} id='contact'>
                        <div className='container d-flex justify-content-center align-items-center'>
                            <div className='row w-100'>
                                <div className='col-12 col-md-5 position-relative mobile-footer-col' >
                                    <div className='row w-100' >
                                        <img className='footer-rock' src={rock} />
                                        <div className='position-absolute' style={{zIndex:"2",top:"0%"}}>
                                            <div className='col-12'>
                                                <img src={logo}></img>
                                            </div>
                                            <div className='col-12'>
                                                <p className='mt-3' style={{lineHeight:"21px",textAlign:"left",fontSize:"14px"}}>
                                                    {t('kodpilotDesc')}
                                                    </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-3'>
                                    <div className='row w-100'>
                                        <div className='col-12'>
                                            <p style={{color:"#FC0DC3",textAlign:"left"}}>MENÜ</p>
                                        </div>
                                        <div className='col-12'>
                                            <p className='mt-3' style={{lineHeight:"21px",textAlign:"left"}}>
                                            <Nav className="flex-column nav-body">
                                            <Link onClick={goToTop}
                                                    activeClass='active-link'
                                                    spy={true}
                                                    smooth={true}
                                                    offset={-70}
                                                    duration={50}
                                                    style={{cursor:"pointer"}}
                                                >
                                                    {t('menuHome')}
                                                </Link>
                                                <Link to="about"
                                                    activeClass='active-link'
                                                    spy={true}
                                                    smooth={true}
                                                    offset={-70}
                                                    duration={50}
                                                    style={{cursor:"pointer"}}
                                                >
                                                    {t('menuAbout')}
                                                </Link>
                                                <Link to="brands"
                                                    activeClass='active-link'
                                                    spy={true}
                                                    smooth={true}
                                                    offset={-70}
                                                    duration={50}
                                                    style={{cursor:"pointer"}}
                                                >
                                                    {t('menuBrands')}
                                                </Link>
                                                <Link to="what"
                                                    activeClass='active-link'
                                                    spy={true}
                                                    smooth={true}
                                                    offset={-70}
                                                    duration={50}
                                                    style={{cursor:"pointer"}}
                                                >
                                                    {t('menuWhatWeDo')}
                                                </Link>
                                                <Link to="scholarship"
                                                    activeClass='active-link'
                                                    spy={true}
                                                    smooth={true}
                                                    offset={-70}
                                                    duration={50}
                                                    style={{cursor:"pointer"}}
                                                >
                                                    {t('menuScholarship')}
                                                </Link>
                                                <Link to="contact"
                                                    activeClass='active-link'
                                                    spy={true}
                                                    smooth={true}
                                                    offset={-70}
                                                    duration={50}
                                                    style={{cursor:"pointer"}}
                                                >
                                                    {t('menuContact')}
                                                </Link>
                                            </Nav>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-4'>
                                    <div className='row w-100'>
                                        <div className='col-12'>
                                            <p className='footerp'>{t('menuContact')}</p>
                                        </div>
                                        <div className='col-12'>
                                            <a href='mailto:info@kodpilot.com' target='_blank'> <p className='footerp footer_contact'>info@kodpilot.com </p></a>
                                            <a href='tel:0850 307 3637'> <p className='footerp footer_contact'>0(850) 307 3637</p></a>
                                            <a href='https://goo.gl/maps/bpStyZVggfvBRsdh6' target='_blank'> <p className='footerp footer_contact'>Odunluk Mah. Odunluk Cad. <br/>N0:13/C, Nilüfer/BURSA</p></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Footer;
