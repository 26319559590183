import { Route, Routes } from "react-router";
import Home from '../pages/Home/Home';
import Header from "../pages/Layouts/Header";
import Sidebar from "../pages/Layouts/Sidebar";
import Footer from "../pages/Layouts/Footer";
import logo from "../assets/images/logo.svg"
import { useEffect, useState } from "react";

function Router() {
  // true false state headere ver
  const [open, setOpen] = useState(true);
  useEffect(() => {
    window.addEventListener("scroll", () => {
        const position = window.pageYOffset;
        if (position > 200) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    })
  })
  return (
    <>


      <Header open = {open} setOpen = {setOpen} />
      {open && <Sidebar />} 
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
   
      <Footer/>
    </>
  );
}

export default Router;
