// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/background/meetus_bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.meetus-body {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-size: contain;
    height: 120vh;
    /* background-position: center; */
    width: 100%;
    max-width: 100%;
    transform: translateY(-10%);

  }
  .meetus-body h1 {
    font-family: "Chakra Petch";
    color: #ffffff;
    font-size: 60px;
    font-weight: 700;
    margin-top: 25%;

}
.meetus-body .swiper-container{
    transform: translateY(25%);
}
.meetus-slide{
    height: 400px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/components/meetus.css"],"names":[],"mappings":"AAAA;IACI,yDAA8D;IAC9D,4BAA4B;IAC5B,wBAAwB;IACxB,aAAa;IACb,iCAAiC;IACjC,WAAW;IACX,eAAe;IACf,2BAA2B;;EAE7B;EACA;IACE,2BAA2B;IAC3B,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,eAAe;;AAEnB;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,aAAa;AACjB","sourcesContent":[".meetus-body {\n    background-image: url(\"../../images/background/meetus_bg.png\");\n    background-repeat: no-repeat;\n    background-size: contain;\n    height: 120vh;\n    /* background-position: center; */\n    width: 100%;\n    max-width: 100%;\n    transform: translateY(-10%);\n\n  }\n  .meetus-body h1 {\n    font-family: \"Chakra Petch\";\n    color: #ffffff;\n    font-size: 60px;\n    font-weight: 700;\n    margin-top: 25%;\n\n}\n.meetus-body .swiper-container{\n    transform: translateY(25%);\n}\n.meetus-slide{\n    height: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
