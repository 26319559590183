import React from 'react'
import '../assets/css/components/about.css'
import { Image } from 'react-bootstrap'
import ready from '../assets/images/title2.svg'
import ready_en from '../assets/images/title2_en.svg'
import stones from '../assets/images/stones.png'
import { Swiper, SwiperSlide } from 'swiper/react';

import kurumsal from '../assets/images/kurumsal.png'
import eticaret from '../assets/images/eticaret.png'
import blockchain from '../assets/images/blockchain.png'
import mobil from '../assets/images/mobil.png'
import google from '../assets/images/google.png'
import ozel from '../assets/images/ozel.png'

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import { EffectCoverflow, Pagination } from "swiper";
import { useTranslation } from 'react-i18next';

function About() {
    const { t,i18n } = useTranslation();
    return (
        <>
            <div className='about-body' id="about">
                {(i18n.language == 'tr') ? (
                     <Image src={ready} className='ready-img w-50 rounded mx-auto d-block' alt='allReady' />
                ) : (
                    <Image src={ready_en} className='ready-img w-50 rounded mx-auto d-block' alt='allReady' />
                )}
             
                <div className='title-body'>
                    <h1 className='title'>{t('aboutUsTitle')} </h1>
                </div>

                <div className='content-right-body'>
                    <div className='frame'>
                    <p className='content-right' dangerouslySetInnerHTML={{ __html:t('aboutUs2') }} />
                    </div>
                </div>
                <div className='content-left-body'>
                    <div className='content-left-border'>
                        <div className='left-area'>
                            <p className='content-left'>
                               <p dangerouslySetInnerHTML={{ __html:t('aboutUs1') }} />
                             
                            </p>
                        </div>
                    </div>
                </div>
                <div className='stones-body'>
                    <Image src={stones} className='stones' alt='stones' />
                </div>
                <Swiper
                    effect={"coverflow"}
                    // dir="rtl"
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={"auto"}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    // loop={true}
                    pagination={false}
                    modules={[EffectCoverflow]}
                    initialSlide={3}
                    className="mySwiper about-swiper"
                >
                    <SwiperSlide>
                        <div className='swiper-div'>
                            <img src={kurumsal} />
                            <h1>{t('corporate')}</h1>
                        </div>
                       
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='swiper-div'>
                            <img src={eticaret} />
                            <h1>{t('ecommerce')}</h1>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='swiper-div'>
                            <img src={blockchain} />
                            <h1>{t('blockchain')}</h1>
                        </div>

                    </SwiperSlide>
                    <SwiperSlide>
                    <div className='swiper-div'>
                            <img src={mobil} />
                            <h1>{t('mobile')}</h1>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className='swiper-div'>
                            <img src={ozel} />
                            <h1>{t('startUp')}</h1>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                    <div className='swiper-div'>
                            <img src={google} />
                            <h1>{t('google')}</h1>
                        </div>
                    </SwiperSlide>
                   
                </Swiper>
            </div>
        </>
    )
}

export default About
