import React from 'react'
import '../assets/css/components/whatWe.css'
import { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import test from '../assets/images/what-we-do-slider.svg'
import what from '../assets/images/what.png'
import backicon from '../assets/images/icons/backicon.svg'
import nexticon from '../assets/images/icons/nexticon.svg'
import gameinvest from '../assets/images/gameinvest.png'
import create from '../assets/images/create.png'
import bambu from '../assets/images/bambu.png'
import dyned from '../assets/images/dyned.png'
import slinger from '../assets/images/slinger.png'
import pw from '../assets/images/pw.png'
import { useTranslation } from 'react-i18next';
function WhatWeDo() {
    const { t } = useTranslation();
    const navigationPrevRef = React.useRef(null)
    const navigationNextRef = React.useRef(null)
    return(
        <>
            <div className='what-we-do-body' id='what'>
                <div className='container'>
                    <h1 className='mb-5'>{t('whatWeDoTitle')}</h1>
                    <Swiper
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current,
                          }}
                        modules={[Navigation]}
                        className="doMySwiper"
                        onBeforeInit={(swiper) => {
                            swiper.params.navigation.prevEl = navigationPrevRef.current;
                            swiper.params.navigation.nextEl = navigationNextRef.current;
                       }}
                    >
                        <SwiperSlide>
                            <div className='position-relative'>
                                <img className='slider-photo' src={bambu}/>
                                <div className='content'>
                                    <div className='content-left-border'>
                                        <div className='left-area'>
                                            <div className='text-area'>
                                                <h1>BAMBUGAMES</h1>
                                                <p className='content-left'>
                                                {t('bambugames')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='position-relative'>
                                <img className='slider-photo' src={gameinvest}/>
                                <div className='content'>
                                    <div className='content-left-border'>
                                        <div className='left-area'>
                                            <div className='text-area'>
                                                <h1>Gaminvest</h1>
                                                <p className='content-left'>
                                              {t('Gaminvest')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='position-relative'>
                                <img className='slider-photo' src={dyned}/>
                                <div className='content'>
                                    <div className='content-left-border'>
                                        <div className='left-area'>
                                            <div className='text-area'>
                                                <h1>DYNEDSINAVI</h1>
                                                <p className='content-left'>
                                                {t('dynedsinavi')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='position-relative'>
                                <img className='slider-photo' src={slinger}/>
                                <div className='content'>
                                    <div className='content-left-border'>
                                        <div className='left-area'>
                                            <div className='text-area'>
                                                <h1>SLINGER</h1>
                                                <p className='content-left'>
                                                {t('slinger')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='position-relative'>
                                <img className='slider-photo' src={pw}/>
                                <div className='content'>
                                    <div className='content-left-border'>
                                        <div className='left-area'>
                                            <div className='text-area'>
                                                <h1>PROFILEWALLET</h1>
                                                <p className='content-left'>
                                                {t('profileWallet')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <div className='whatdoswiper-btn backbtn' ref={navigationPrevRef} >
                            <div className='backicon-border'>
                            <img className='backicon' src={backicon}></img>
                            </div>
                        </div>
                        <div className='whatdoswiper-btn nextbtn' ref={navigationNextRef} >
                            <div className='backicon-border'>
                            <img className='backicon' src={nexticon}></img>
                            </div>
                        </div>
                    </Swiper>
                    {/* <div className=''>

                    </div> */}
                </div>
            </div>
        </>
    )
}
export default WhatWeDo