// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-body {
  max-width: 90%;
  margin: 0 auto;
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
.header-body .stone-img {
  shape-outside: circle(50%);
  border-radius: 50%;
  width: 140px;
  height: auto;
  border: 4px dashed #4a19ed;
  border-radius: 50%;
  cursor: pointer;
}

.header-body .title-menu {
  font-family: "Chakra Petch";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #ffffff;
  margin-left: -16px;
}

.header-body .menu-icon {
  position: absolute;
  left: 70px;
  cursor: pointer;
}

.header-body .social-body {
  align-items: center;
}

.header-body .social-body img {
  vertical-align: middle;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/header.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,cAAc;EACd,0BAA0B;EAC1B,MAAM;EACN,OAAO;EACP,QAAQ;EACR,YAAY;AACd;AACA;EACE,0BAA0B;EAC1B,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,0BAA0B;EAC1B,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".header-body {\n  max-width: 90%;\n  margin: 0 auto;\n  position: fixed !important;\n  top: 0;\n  left: 0;\n  right: 0;\n  z-index: 999;\n}\n.header-body .stone-img {\n  shape-outside: circle(50%);\n  border-radius: 50%;\n  width: 140px;\n  height: auto;\n  border: 4px dashed #4a19ed;\n  border-radius: 50%;\n  cursor: pointer;\n}\n\n.header-body .title-menu {\n  font-family: \"Chakra Petch\";\n  font-style: normal;\n  font-weight: 500;\n  font-size: 20px;\n  line-height: 26px;\n  color: #ffffff;\n  margin-left: -16px;\n}\n\n.header-body .menu-icon {\n  position: absolute;\n  left: 70px;\n  cursor: pointer;\n}\n\n.header-body .social-body {\n  align-items: center;\n}\n\n.header-body .social-body img {\n  vertical-align: middle;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
