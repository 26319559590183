import React from 'react'
import { Container } from 'react-bootstrap'
import '../../assets/css/home.css'
import { About, Brands, ParallaxFirst, WhatWeDo } from '../../components'
import MeetUs from '../../components/MeetUs'
import Scholarship from '../../components/Scholarship'



function Home() {

    return (
        <>
            <Container fluid className='home-body'>
                <ParallaxFirst />
                <About />
                <Brands />
                <WhatWeDo />
                <MeetUs />
                <Scholarship/>
                
            </Container >
        </>
    )
}
export default Home
