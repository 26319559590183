import React from 'react';
import './assets/css/style.css';
import './assets/css/media.css';
import Router from './router/router';
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store/store';
import "./i18n";

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </Provider>
  );
}

createRoot(document.getElementById('root')).render(<App />);