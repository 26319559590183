import React from 'react'
import { Container, Image, NavLink, Navbar, } from 'react-bootstrap';
import '../../assets/css/header.css'
import logo from '../../assets/images/logo.svg'
import youtube from '../../assets/images/icons/youtube.svg'
import instagram from '../../assets/images/icons/instagram.svg'
import stone from '../../assets/images/icons/stone.svg'
import menu from '../../assets/images/icons/menu.svg'



function Header(props) {
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    const { open, setOpen } = props;

    return (
        <>
            <Navbar className='header-body'>
                <Container fluid>
                    <Image onClick={()=>setOpen(!open)} className='stone-img' src={stone} alt="menu" />
                    <Image onClick={()=>setOpen(!open)} src={menu} alt='menu-icon' className='menu-icon' />
                    <h1 className='title-menu'>MENU</h1>

                    <Image href="#first" onClick={()=>goToTop()} className='logo rounded mx-auto d-block' src={logo} alt='logo' style={{cursor:"pointer"}}/>

                    <div className='d-flex social-body'>
                        <NavLink href='https://www.youtube.com/@kodpilottv9418' target='_blank' >
                            <Image className='social-youtube' src={youtube} alt='youtube' />
                        </NavLink>
                        <NavLink href='https://www.instagram.com/kodpilot/' target='_blank'>
                            <Image className='social-instagram' src={instagram} alt='instagram' />
                        </NavLink>
                    </div>
                </Container>
            </Navbar>
        </>
    )
}
export default Header;
