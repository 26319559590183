import ButtonModel from './ButtonModel';
import ParallaxFirst from './ParallaxFirst';
import About from './About';
import Brands from './Brands';
import WhatWeDo from './WhatDo';
export {
    ButtonModel,
    ParallaxFirst,
    About,
    Brands,
    WhatWeDo
}