import React from 'react'
import '../assets/css/components/meetus.css'

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import backicon from '../assets/images/icons/backicon.svg'
import nexticon from '../assets/images/icons/nexticon.svg'
import stone from '../assets/images/tassak.svg'
import enes from '../assets/images/crewmember.svg'
import mert from '../assets/images/mert.svg'
import sefa from '../assets/images/sefa.svg'
import zafer from '../assets/images/zafer.svg'
import sergen from '../assets/images/sergen.svg'
import lamine from '../assets/images/lamine.svg'
import timur from '../assets/images/timur.svg'
import safiye from '../assets/images/safiye.svg'
import pilot from '../assets/images/pilot.svg'

import { EffectCoverflow, Navigation, Pagination } from 'swiper';
import { useTranslation } from 'react-i18next';
function MeetUs() {
    const { t } = useTranslation();
 
    return (
        <>
        <div className='meetus-body d-flex justify-content-center align-items-center'>
            <div className='container-fluid d-flex justify-content-center align-items-center'>
                <div className='row w-100 d-flex justify-content-center align-items-center'>
                    <div className='col-12 d-flex justify-content-center align-items-center'>
                        <h1 className='mb-5'>{t('meetUs')}</h1>
                    </div>
                    <div className='swiper-container col-12 d-flex justify-content-center align-items-center'>
                    <Swiper
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={"auto"}
                        pagination={true}
                        className="mySwiper"
                        initialSlide={3}
                        loop={true}
                        breakpoints={{
                            // when window width is >= 640px
                            640: {
                              width: 640,
                              slidesPerView: 1,
                            },
                            // when window width is >= 768px
                            768: {
                              width: 768,
                              slidesPerView: 2,
                            },
                          }}
                        spaceBetween={0}
                        style={{width:"1500px",maxWidth:"100%",height:"auto"}}
                    >
                        <SwiperSlide >
                            <img className='meetus-slide' src={lamine} />
                        </SwiperSlide>
                        {/* <SwiperSlide>
                        <img className='meetus-slide' src={sefa} />
                        </SwiperSlide> */}
                        <SwiperSlide>
                        <img className='meetus-slide' src={mert} />
                        </SwiperSlide>
                        <SwiperSlide>
                        <img className='meetus-slide' src={zafer} />
                        </SwiperSlide>
                        <SwiperSlide>
                        <img className='meetus-slide' src={enes} />
                        </SwiperSlide>
                        {/* <SwiperSlide>
                        <img className='meetus-slide' src={sergen} />
                        </SwiperSlide> */}
                        <SwiperSlide>
                        <img className='meetus-slide' src={timur} />
                        </SwiperSlide>
                        {/* <SwiperSlide>
                        <img className='meetus-slide' src={safiye} />
                        </SwiperSlide> */}
                        <SwiperSlide>
                        <img className='meetus-slide' src={pilot} />
                        </SwiperSlide>
                    </Swiper>
                    </div>
                    <div className='col-12 d-flex justify-content-center'>
                        <img className='meetus-stone' style={{maxWidth:"100%"}} src={stone}></img>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default MeetUs
